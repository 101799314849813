.loading {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  place-content: center;
  text-align: center;
  background: "#91d5ff";
  border-radius: 4px;
}

.csv-button {
  border-style: solid;
  border-radius: 50px;
  border-color: #40a9ff;
  font-size: 40px;
  padding: 7px;
  /* margin-bottom: 10px; */
  color: white;
  background-color: #40a9ff;
}

.csv-button:hover {
  background-color: #91d5ff;
  border-color: #91d5ff;
}

.space-card {
  gap: 50px !important;
}

.register-link:hover{
  color: #1890ff !important;
}

/* .ant-table-thead > tr > th {
  text-align: center;
  font-weight: bold;
  font-size: 25;
  color: white;
  background-color: #001529;
}

.ant-table table {
  font-weight: 450;
  font-size: xx-large;
  text-align: center;
  color: black;
  cursor: pointer;
} */
